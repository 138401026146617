import ResumableUpload from "./ResumableUpload";
import {
  STATUS_UPLOADING,
  STATUS_LIMIT_REACH_ERROR,
  STATUS_CREATING_JOB,
  STATUS_LABELS,
  STATUS_INTERRUPTED,
  STATUS_UNAUTHORIZED,
  STATUS_EXPORTING,
} from "~/fc/Constants/job-status";
import {
  handleJobCreationError,
  handleUploadError,
} from "./ConversionErrorHandlers";
import { completeUpload, joinChunks } from "./ChunkUploadHandler";
import HttpService from "../HttpService";
import { pauseExecution } from "../helpers";

class Job {
  constructor(tasks, type = "manipulation") {
    this.preTasks = tasks || [];
    this.type = type;
    this.job = null;
    this.token = $nuxt.$auth?.token || null;
  }

  /**
   * Getter of the status of the job
   */
  get Status() {
    return this.job.status;
  }

  /**
   * Process download by creating a job
   *
   * @returns {Promise<Object>} - A promise that resolves with the created job data.
   */
  async processDownload() {
    // create job from API
    const { data } = await HttpService.process()
      .setPath("/jobs")
      .setToken(this.token)
      .setBody({ tasks: this.preTasks })
      .post();

    this.job = data;

    // set the job in store
    $nuxt.$store.commit("items/setJob", {
      ...this.job,
      status: STATUS_EXPORTING,
      type: this.type,
    });

    // start processing the tasks of this job
    if (this.job.status !== STATUS_LIMIT_REACH_ERROR) {
      for (const task of this.job.tasks) {
        $nuxt.$store.commit("items/setTask", task);
      }
    }

    return this.job;
  }

  /**
   * Process a single file asynchronously.
   *
   * @param {Object} options - The options object.
   * @param {Object} options.file - The file object to process.
   * @param {boolean} [options.returnJob=false] - Indicates whether to return the job object or its ID.
   * @param {string} options.jobId - The ID of the job.
   * @returns {Promise<Object|string>} - A promise that resolves to either the job object or its ID.
   */
  async processSingleFile({ file, returnJob = false, jobId }) {
    // set a temporary job in the store
    $nuxt.$store.commit("items/setJob", {
      id: jobId,
      step: STATUS_CREATING_JOB,
      tasks: [],
      type: this.type,
      status: STATUS_CREATING_JOB,
      statusText: STATUS_LABELS[STATUS_CREATING_JOB],
      statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_CREATING_JOB]),
      // it will not create socket subscription for this temporary job
      ignoreSocketSubscribe: true,
    });

    // update the 'job_id' of the file for this temporary job
    $nuxt.$store.commit("changeFileState", {
      id: file.id,
      job_id: jobId,
    });

    try {
      // create the actual job from API
      this.job = await this.createJob();

      // set this actual job in store
      $nuxt.$store.commit("items/setJob", {
        ...this.job,
        type: this.type,
        status: STATUS_UPLOADING,
        statusText: STATUS_LABELS[STATUS_UPLOADING],
        statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_UPLOADING]),
      });

      // update the 'job_id' of the file for this actual job
      $nuxt.$store.commit("changeFileState", {
        id: file.id,
        job_id: this.job.id,
      });

      // remove the previous temporary job from the store
      $nuxt.$store.commit("items/removeJob", jobId);

      // start processing the tasks of this job
      if (this.job.status !== STATUS_LIMIT_REACH_ERROR) {
        for (const task of this.job.tasks) {
          $nuxt.$store.commit("items/setTask", task);
          switch (task.operation) {
            case "import/google-drive":
            case "import/url":
              $nuxt.$store.commit("items/startTask", task);
              break;
            case "import/upload":
              this.processUploadTask(file, task);
              break;
          }
        }
      }

      return returnJob ? this.job : this.job.id;
    } catch (err) {
      return handleJobCreationError({
        jobId,
        err,
        file,
        returnJob,
        preTasks: this.preTasks,
        type: this.type,
      });
    }
  }

  /**
   * Process multiple files as a single job.
   *
   * @param {Object} options - The options for bulk processing.
   * @param {Object[]} options.uploads - The uploads to process.
   * @param {string} options.jobId - The ID of the job.
   * @returns {Promise<string>} - A promise that resolves with the job ID.
   */
  async processBulkFiles({ uploads, jobId }) {
    // set a temporary job in store
    $nuxt.$store.commit("items/setJob", {
      id: jobId,
      step: STATUS_CREATING_JOB,
      tasks: [],
      type: this.type,
      status: STATUS_CREATING_JOB,
      statusText: STATUS_LABELS[STATUS_CREATING_JOB],
      statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_CREATING_JOB]),
      isBulkItemJob: true,
      // it will not create socket subscription for this temporary job
      ignoreSocketSubscribe: true,
    });

    // set this temporary job as BulkItemJobId
    $nuxt.$store.commit("items/setBulkItemJobId", jobId);

    try {
      // create the  actual job from API
      this.job = await this.createJob();

      // set this actual job in store
      $nuxt.$store.commit("items/setJob", {
        ...this.job,
        type: this.type,
        status: STATUS_UPLOADING,
        statusText: STATUS_LABELS[STATUS_UPLOADING],
        statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_UPLOADING]),
        isBulkItemJob: true,
      });

      // set this actual job as BulkItemJobId
      $nuxt.$store.commit("items/setBulkItemJobId", this.job.id);

      // remove the previous temporary job from the store
      $nuxt.$store.commit("items/removeJob", jobId);

      // start processing the tasks of this job
      for (const task of this.job.tasks) {
        $nuxt.$store.commit("items/setTask", task);
        if (task.operation === "import/upload") {
          this.processUploadTask(uploads[task.name], task);
        }
      }

      return this.job.id;
    } catch (err) {
      return handleJobCreationError({
        jobId,
        err,
        file: {},
        returnJob: false,
        preTasks: this.preTasks,
        type: this.type,
        isBulkItemJob: true,
        uploads,
      });
    }
  }

  /**
   * Process the upload task for a file.
   *
   * @param {Object} file - The file object to upload.
   * @param {Object} task - The task object representing the upload task.
   * @returns {Promise<void>} - A promise that resolves when the upload task is completed.
   */
  async processUploadTask(file, task) {
    // start the task in the store
    $nuxt.$store.commit("items/startTask", task);
    let resumableUploadData = null;

    try {
      // upload chunks via resumable lib (STEP 2)
      resumableUploadData = await new ResumableUpload(this.job, file)
        .setTask(task)
        .upload();
      // after the chunk upload completed, call chunk-join api (STEP 3)
      await joinChunks({ ...resumableUploadData, fileSize: file.total });

      // after the chunks join completed, call upload-complete api (STEP 4)
      await completeUpload(resumableUploadData);
    } catch (err) {
      const identifier =
        resumableUploadData && resumableUploadData.identifier
          ? resumableUploadData.identifier
          : "";
      handleUploadError({
        err,
        jobId: this.job.id,
        taskId: task.id,
        fileUploadUrl: task.result.form.url,
        identifier,
        signature: task.result.form.parameters.signature,
        fileName: file.name,
        fileSize: file.total,
      });
    }
  }

  /**
   * Create a job from the API with retry logic.
   *
   * @returns {Promise<Object>} - A promise that resolves with the created job data.
   * @throws {Object} - An error object with relevant properties if the creation fails.
   */
  async createJob() {
    const totalRetry = 3;
    for (let retry = 0; retry < totalRetry; retry++) {
      try {
        const { data } = await HttpService.process()
          .setPath("/jobs")
          .setToken(this.token)
          .setBody({ tasks: this.preTasks })
          .post();
        return data;
      } catch (e) {
        const userId = $nuxt.$auth?.user?.id || "guest";

        // authorization error
        if (e.response && e.response.status === 401) {
          return Promise.reject({
            step: STATUS_CREATING_JOB,
            userId,
            code: STATUS_UNAUTHORIZED,
            status: STATUS_LABELS[STATUS_UNAUTHORIZED],
            message: $nuxt.$t("not_authorized"),
          });
        }

        // permanent error
        if (e.response && e.response.status <= 501) {
          return Promise.reject({
            step: STATUS_CREATING_JOB,
            userId,
            code: e.code,
            status: e.response?.status,
            message: e.response?.data,
          });
        }

        // all retries failed
        if (retry === totalRetry - 1) {
          let message = e?.message || "";
          if (
            e.response &&
            e.response.data &&
            typeof e.response.data === "string"
          ) {
            message = e.response.data;
          }
          return Promise.reject({
            step: STATUS_CREATING_JOB,
            userId,
            code: e?.code,
            status: STATUS_LABELS[STATUS_INTERRUPTED],
            message,
          });
        }

        // pause for 5s before the next retry
        await pauseExecution(5000);
      }
    }
  }
}

export default Job;
