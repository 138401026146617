import axios from "axios";
import {
  STATUS_ERROR,
  STATUS_LABELS,
  STATUS_VALIDATING_UPLOAD,
  STATUS_FINISHING_UPLOAD,
  STATUS_INTERRUPTED,
  STATUS_CREATING_JOB,
} from "~/fc/Constants/job-status";
import { handleUploadError } from "./ConversionErrorHandlers";
import Job from "./job";
import { pauseExecution } from "../helpers";
const TASK_COMPLETED = "completed";
const TASK_FAILED = "failed";

/**
 * Call API to join all the uploaded chunks (STEP:3)
 *
 * @param {Object} options - The options for processing on chunk upload complete.
 * @param {string} options.id - The ID of the job.
 * @param {string} options.taskId - The ID of the task.
 * @param {string} options.fileUploadUrl - The URL for file upload.
 * @param {string} options.fileName - The name of the file.
 * @param {string} options.signature - The signature for authentication.
 * @param {string} options.identifier - The identifier for the upload.
 * @returns {Promise<Object>} - A promise that resolves with the processing result.
 * @throws {Object} - An error object if the processing fails.
 */
export const joinChunks = ({
  id,
  taskId,
  fileUploadUrl,
  fileName,
  signature,
  identifier,
  fileSize
}) => {
  return new Promise(async (resolve, reject) => {
    const j = $nuxt.$store.state.items.jobs[id];
    const t = $nuxt.$store.state.items.tasks[taskId];

    // return if job is already in error state
    if (!j || j.status === STATUS_ERROR) {
      return;
    }

    // return if task is already in completed or error state
    if (!t || t.status === TASK_COMPLETED || t.status === TASK_FAILED) {
      return;
    }

    // change the job status
    $nuxt.$store.commit("items/setJobProgress", {
      id,
      status: STATUS_VALIDATING_UPLOAD,
      statusText: STATUS_LABELS[STATUS_VALIDATING_UPLOAD],
      statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_VALIDATING_UPLOAD]),
      progress: 0,
    });

    const totalRetry = 3;
    const formData = new FormData();
    formData.append("identifier", identifier);
    formData.append("fileSize", fileSize);

    for (let retry = 0; retry < totalRetry; retry++) {
      try {
        await axios.post(
          fileUploadUrl
            .replace("api/upload", "api/resumable/join")
            .replace("server", "s"),
          formData
        );
        return resolve({
          id,
          taskId,
          fileUploadUrl,
          fileName,
          signature,
          identifier,
        });
      } catch (e) {
        // permanent error
        if (e.response && e.response.status <= 501) {
          $nuxt.$sentry.captureEvent({
            message: "joinChunks",
            extra: {
              reason: `${e?.message} - ${e?.code}`,
            },
          });

          // don't retry, mark the task/job as failed
          return reject({
            status: e.response?.status,
            message: e.response?.data,
            step: STATUS_VALIDATING_UPLOAD,
          });
        }

        // all retries failed
        if (retry === totalRetry - 1) {
          let message = e?.message || "";
          if (
            e.response &&
            e.response.data &&
            typeof e.response.data === "string"
          ) {
            message = e.response.data;
          }
          return reject({
            step: STATUS_VALIDATING_UPLOAD,
            status: STATUS_LABELS[STATUS_INTERRUPTED],
            code: e?.code,
            message,
          });
        }

        // pause for 5s before the next retry
        await pauseExecution(5000);
      }
    }
  });
};

/**
 * Call join-complete API (STEP:4)
 *
 * @param {Object} options - The options for processing on join complete.
 * @param {string} options.id - The ID of the job.
 * @param {string} options.taskId - The ID of the task.
 * @param {string} options.fileUploadUrl - The URL for file upload.
 * @param {string} options.fileName - The name of the file.
 * @param {string} options.signature - The signature for authentication.
 * @param {string} options.identifier - The identifier for the upload.
 * @returns {Promise<Object>} - A promise that resolves with the processing result.
 * @throws {Object} - An error object if the processing fails.
 */
export const completeUpload = ({
  id,
  taskId,
  fileUploadUrl,
  fileName,
  signature,
  identifier,
}) => {
  return new Promise(async (resolve, reject) => {
    const j = $nuxt.$store.state.items.jobs[id];
    const t = $nuxt.$store.state.items.tasks[taskId];

    // return if job is already in error state
    if (!j || j.status === STATUS_ERROR) {
      return;
    }

    // return if task is already in completed or error state
    if (!t || t.status === TASK_COMPLETED || t.status === TASK_FAILED) {
      return;
    }

    // change the job status
    $nuxt.$store.commit("items/setJobProgress", {
      id,
      status: STATUS_FINISHING_UPLOAD,
      statusText: STATUS_LABELS[STATUS_FINISHING_UPLOAD],
      statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_FINISHING_UPLOAD]),
      progress: 0,
    });

    const totalRetry = 3;
    const formData = new FormData();
    formData.append("identifier", identifier);
    formData.append("fileName", fileName);
    formData.append("signature", signature);

    for (let retry = 0; retry < totalRetry; retry++) {
      try {
        await axios.post(fileUploadUrl.replace("server", "s"), formData);
        // no need to update this count for single file
        // we only display this count for multiple files(merge operation)
        if (j.isBulkItemJob) {
          $nuxt.$store.commit("items/increaseTotalUploadedFileCount", id);
        }
        return resolve({ sources: identifier });
      } catch (e) {
        // permanent error
        if (e.response && e.response.status <= 501) {
          $nuxt.$sentry.captureEvent({
            message: "completeUpload",
            extra: {
              reason: `${e?.message} - ${e?.code}`,
            },
          });

          // don't retry, mark the task/job as failed
          return reject({
            status: e.response?.status,
            message: e.response?.data,
            step: STATUS_FINISHING_UPLOAD,
          });
        }

        // all retries failed
        if (retry === totalRetry - 1) {
          let message = e?.message || "";
          if (
            e.response &&
            e.response.data &&
            typeof e.response.data === "string"
          ) {
            message = e.response.data;
          }
          return reject({
            step: STATUS_FINISHING_UPLOAD,
            status: STATUS_LABELS[STATUS_INTERRUPTED],
            code: e?.code,
            message,
          });
        }

        // pause for 5s before the next retry
        await pauseExecution(5000);
      }
    }
  });
};

/**
 * Initiates an interrupted job.
 *
 * @param {Object} job - The interrupted job to initiate.
 */
export const initiateInterruptedJob = async (job) => {
  if (job.step === STATUS_CREATING_JOB && job.isBulkItemJob) {
    try {
      const newJob = new Job(job.preTasks, job.type);
      await newJob.processBulkFiles({
        uploads: job.uploads,
        jobId: job.id,
      });
    } catch (err) { }
  } else if (job.step === STATUS_CREATING_JOB) {
    try {
      const newJob = new Job(job.preTasks, job.type);
      const jobID = await newJob.processSingleFile({
        file: job.file,
        returnJob: job.returnJob,
        jobId: job.id,
      });

      if (job.file && job.file.id) {
        $nuxt.$store.commit("addJobID", { fileID: job.file.id, jobID });
      }
    } catch (err) { }
  } else if (job.step === STATUS_VALIDATING_UPLOAD) {
    try {
      await joinChunks({
        id: job.id,
        taskId: job.taskId,
        fileUploadUrl: job.fileUploadUrl,
        fileName: job.fileName,
        signature: job.signature,
        identifier: job?.identifier,
      });

      await completeUpload({
        id: job.id,
        taskId: job.taskId,
        fileUploadUrl: job.fileUploadUrl,
        fileName: job.fileName,
        signature: job.signature,
        identifier: job?.identifier,
      });
    } catch (err) {
      handleUploadError({
        err,
        jobId: job.id,
        taskId: job.taskId,
        fileUploadUrl: job.fileUploadUrl,
        signature: job.signature,
        fileName: job.fileName,
        identifier: job?.identifier,
        fileSize: job?.fileSize,
      });
    }
  } else if (job.step === STATUS_FINISHING_UPLOAD) {
    try {
      await completeUpload({
        id: job.id,
        taskId: job.taskId,
        fileUploadUrl: job.fileUploadUrl,
        fileName: job.fileName,
        signature: job.signature,
        identifier: job?.identifier,
      });
    } catch (err) {
      handleUploadError({
        err,
        jobId: job.id,
        taskId: job.taskId,
        fileUploadUrl: job.fileUploadUrl,
        signature: job.signature,
        fileName: job.fileName,
        identifier: job?.identifier,
        fileSize: job?.fileSize
      });
    }
  }
};

/**
 * Processes the interrupted jobs.
 * Resumes the execution of each interrupted job, removes it from the interrupted state,
 * and adds a delay between job resumptions.
 */
export const processInterruptedJobs = async () => {
  /**
   * Array of interrupted jobs.
   * @type {Array<Object>}
   */
  const interruptedJobs = $nuxt.$store.state.interruptedJobs;

  for (const job of interruptedJobs) {
    initiateInterruptedJob(job);
    $nuxt.$store.commit("removeJobsFromInterruptedState", {
      id: job.id,
      signature: job.signature,
    });
    await pauseExecution(100); // prevent sending burst requests to the server
  }
};
