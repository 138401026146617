import axios from 'axios'

class HttpService {
  constructor () {
    this.baseUrl = (typeof window !== 'undefined') ? process.env.QUERY_SERVICE : process.env.QUERY_SERVICE_INTERNAL
    this.path = this.baseUrl
    this.body = {}
    this.params = {
      token: process.env.api_key
    }
    this.headers = {}
    this.agent = axios
  }

  query () {
    this.baseUrl = (typeof window !== 'undefined') ? process.env.QUERY_SERVICE : process.env.QUERY_SERVICE_INTERNAL
    return this
  }

  process () {
    this.baseUrl = process.env.PROCESS_SERVICE
    return this
  }

  account () {
    this.baseUrl = process.env.ACCOUNT_SERVICE
    return this
  }

  server () {
    this.baseUrl = process.env.SERVER_SERVICE
    return this
  }

  payment () {
    this.baseUrl = process.env.PAYMENT_SERVICE
    return this
  }

  notification () {
    this.baseUrl = process.env.NOTIFICATION_SERVICE
    return this
  }

  base (base) {
    this.baseUrl = base
    return this
  }

  setToken (token) {
    this.headers.Authorization = 'Bearer ' + token
    return this
  }

  setPath (uri) {
    this.path = this.baseUrl + uri
    return this
  }

  setBody (body) {
    this.body = body
    return this
  }

  setParams (params) {
    this.params = params
    return this
  }

  setHeaders (headers) {
    this.headers = headers
    return this
  }

  setAgent (agent) {
    this.agent = agent
    return this
  }

  get () {
    return this.agent.get(this.path, { params: this.params, headers: this.headers })
  }

  delete () {
    return this.agent.delete(this.path, { params: this.params, headers: this.headers })
  }

  post () {
    return this.agent.post(this.path, this.body, { params: this.params, headers: this.headers })
  }

  patch () {
    return this.agent.patch(this.path, this.body, { params: this.params, headers: this.headers })
  }
}

export default (new HttpService())
