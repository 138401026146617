class FileRepo {
  constructor(ctx) {
    this.store = ctx.$store;
  }

  removeFile(file) {
    const fileInputConcurrentLength =
      this.store.state.fileInputConcurrentLength;
    this.store.commit("remove", file.id);
    this.store.commit("advancedSetting/removeTargetForFile", file.id);
    this.store.commit(
      "setFileInputConcurrentLength",
      fileInputConcurrentLength - 1
    );
  }

  addJobIDtoFile(file, jobID) {
    if (this.store.state.list.filter((it) => it.id === file.id).length) {
      this.store.commit("addJobID", { fileID: file.id, jobID });
      return true;
    }
    return false;
  }

  addFile(file) {
    this.store.commit("addFile", file);
  }
}

export default FileRepo;
