export const OLD_UNITS = [
  "weight-converter",
  "temperature-converter",
  "energy-converter",
  "area-converter",
  "length-converter",
  "short-ton-to-kg",
  "short-ton-to-grams",
  "short-ton-to-milligrams",
  "short-ton-to-micrograms",
  "short-ton-to-long-ton",
  "short-ton-to-stone",
  "short-ton-to-lbs",
  "short-ton-to-oz",
  "kg-to-short-ton",
  "kg-to-grams",
  "kg-to-milligrams",
  "kg-to-micrograms",
  "kg-to-long-ton",
  "kg-to-stone",
  "kg-to-lbs",
  "kg-to-oz",
  "grams-to-short-ton",
  "grams-to-kg",
  "grams-to-milligrams",
  "grams-to-micrograms",
  "grams-to-long-ton",
  "grams-to-stone",
  "grams-to-lbs",
  "grams-to-oz",
  "milligrams-to-short-ton",
  "milligrams-to-kg",
  "milligrams-to-grams",
  "milligrams-to-micrograms",
  "milligrams-to-long-ton",
  "milligrams-to-stone",
  "milligrams-to-lbs",
  "milligrams-to-oz",
  "micrograms-to-short-ton",
  "micrograms-to-kg",
  "micrograms-to-grams",
  "micrograms-to-milligrams",
  "micrograms-to-long-ton",
  "micrograms-to-stone",
  "micrograms-to-lbs",
  "micrograms-to-oz",
  "long-ton-to-short-ton",
  "long-ton-to-kg",
  "long-ton-to-grams",
  "long-ton-to-milligrams",
  "long-ton-to-micrograms",
  "long-ton-to-stone",
  "long-ton-to-lbs",
  "long-ton-to-oz",
  "stone-to-short-ton",
  "stone-to-kg",
  "stone-to-grams",
  "stone-to-milligrams",
  "stone-to-micrograms",
  "stone-to-long-ton",
  "stone-to-lbs",
  "stone-to-oz",
  "lbs-to-short-ton",
  "lbs-to-kg",
  "lbs-to-grams",
  "lbs-to-milligrams",
  "lbs-to-micrograms",
  "lbs-to-long-ton",
  "lbs-to-stone",
  "lbs-to-oz",
  "oz-to-short-ton",
  "oz-to-kg",
  "oz-to-grams",
  "oz-to-milligrams",
  "oz-to-micrograms",
  "oz-to-long-ton",
  "oz-to-stone",
  "oz-to-lbs",
  "fahrenheit-to-rankine",
  "fahrenheit-to-celsius",
  "fahrenheit-to-kelvin",
  "rankine-to-fahrenheit",
  "rankine-to-celsius",
  "rankine-to-kelvin",
  "celsius-to-fahrenheit",
  "celsius-to-rankine",
  "celsius-to-kelvin",
  "kelvin-to-fahrenheit",
  "kelvin-to-rankine",
  "kelvin-to-celsius",
  "joules-to-kilojoules",
  "joules-to-megajoules",
  "joules-to-gigajoules",
  "joules-to-gram-calories",
  "joules-to-kilocalories",
  "joules-to-watt-hours",
  "joules-to-kilowatt-hour",
  "joules-to-megawatt-hours",
  "joules-to-ev",
  "joules-to-btu",
  "joules-to-kbtu",
  "joules-to-foot-pounds",
  "kilojoules-to-joules",
  "kilojoules-to-megajoules",
  "kilojoules-to-gigajoules",
  "kilojoules-to-gram-calories",
  "kilojoules-to-kilocalories",
  "kilojoules-to-watt-hours",
  "kilojoules-to-kilowatt-hour",
  "kilojoules-to-megawatt-hours",
  "kilojoules-to-ev",
  "kilojoules-to-btu",
  "kilojoules-to-kbtu",
  "kilojoules-to-foot-pounds",
  "megajoules-to-joules",
  "megajoules-to-kilojoules",
  "megajoules-to-gigajoules",
  "megajoules-to-gram-calories",
  "megajoules-to-kilocalories",
  "megajoules-to-watt-hours",
  "megajoules-to-kilowatt-hour",
  "megajoules-to-megawatt-hours",
  "megajoules-to-ev",
  "megajoules-to-btu",
  "megajoules-to-kbtu",
  "megajoules-to-foot-pounds",
  "gigajoules-to-joules",
  "gigajoules-to-kilojoules",
  "gigajoules-to-megajoules",
  "gigajoules-to-gram-calories",
  "gigajoules-to-kilocalories",
  "gigajoules-to-watt-hours",
  "gigajoules-to-kilowatt-hour",
  "gigajoules-to-megawatt-hours",
  "gigajoules-to-ev",
  "gigajoules-to-btu",
  "gigajoules-to-kbtu",
  "gigajoules-to-foot-pounds",
  "gram-calories-to-joules",
  "gram-calories-to-kilojoules",
  "gram-calories-to-megajoules",
  "gram-calories-to-gigajoules",
  "gram-calories-to-kilocalories",
  "gram-calories-to-watt-hours",
  "gram-calories-to-kilowatt-hour",
  "gram-calories-to-megawatt-hours",
  "gram-calories-to-ev",
  "gram-calories-to-btu",
  "gram-calories-to-kbtu",
  "gram-calories-to-foot-pounds",
  "kilocalories-to-joules",
  "kilocalories-to-kilojoules",
  "kilocalories-to-megajoules",
  "kilocalories-to-gigajoules",
  "kilocalories-to-gram-calories",
  "kilocalories-to-watt-hours",
  "kilocalories-to-kilowatt-hour",
  "kilocalories-to-megawatt-hours",
  "kilocalories-to-ev",
  "kilocalories-to-btu",
  "kilocalories-to-kbtu",
  "kilocalories-to-foot-pounds",
  "watt-hours-to-joules",
  "watt-hours-to-kilojoules",
  "watt-hours-to-megajoules",
  "watt-hours-to-gigajoules",
  "watt-hours-to-gram-calories",
  "watt-hours-to-kilocalories",
  "watt-hours-to-kilowatt-hour",
  "watt-hours-to-megawatt-hours",
  "watt-hours-to-ev",
  "watt-hours-to-btu",
  "watt-hours-to-kbtu",
  "watt-hours-to-foot-pounds",
  "kilowatt-hour-to-joules",
  "kilowatt-hour-to-kilojoules",
  "kilowatt-hour-to-megajoules",
  "kilowatt-hour-to-gigajoules",
  "kilowatt-hour-to-gram-calories",
  "kilowatt-hour-to-kilocalories",
  "kilowatt-hour-to-watt-hours",
  "kilowatt-hour-to-megawatt-hours",
  "kilowatt-hour-to-ev",
  "kilowatt-hour-to-btu",
  "kilowatt-hour-to-kbtu",
  "kilowatt-hour-to-foot-pounds",
  "megawatt-hours-to-joules",
  "megawatt-hours-to-kilojoules",
  "megawatt-hours-to-megajoules",
  "megawatt-hours-to-gigajoules",
  "megawatt-hours-to-gram-calories",
  "megawatt-hours-to-kilocalories",
  "megawatt-hours-to-watt-hours",
  "megawatt-hours-to-kilowatt-hour",
  "megawatt-hours-to-ev",
  "megawatt-hours-to-btu",
  "megawatt-hours-to-kbtu",
  "megawatt-hours-to-foot-pounds",
  "ev-to-joules",
  "ev-to-kilojoules",
  "ev-to-megajoules",
  "ev-to-gigajoules",
  "ev-to-gram-calories",
  "ev-to-kilocalories",
  "ev-to-watt-hours",
  "ev-to-kilowatt-hour",
  "ev-to-megawatt-hours",
  "ev-to-btu",
  "ev-to-kbtu",
  "ev-to-foot-pounds",
  "btu-to-joules",
  "btu-to-kilojoules",
  "btu-to-megajoules",
  "btu-to-gigajoules",
  "btu-to-gram-calories",
  "btu-to-kilocalories",
  "btu-to-watt-hours",
  "btu-to-kilowatt-hour",
  "btu-to-megawatt-hours",
  "btu-to-ev",
  "btu-to-kbtu",
  "btu-to-foot-pounds",
  "kbtu-to-joules",
  "kbtu-to-kilojoules",
  "kbtu-to-megajoules",
  "kbtu-to-gigajoules",
  "kbtu-to-gram-calories",
  "kbtu-to-kilocalories",
  "kbtu-to-watt-hours",
  "kbtu-to-kilowatt-hour",
  "kbtu-to-megawatt-hours",
  "kbtu-to-ev",
  "kbtu-to-btu",
  "kbtu-to-foot-pounds",
  "foot-pounds-to-joules",
  "foot-pounds-to-kilojoules",
  "foot-pounds-to-megajoules",
  "foot-pounds-to-gigajoules",
  "foot-pounds-to-gram-calories",
  "foot-pounds-to-kilocalories",
  "foot-pounds-to-watt-hours",
  "foot-pounds-to-kilowatt-hour",
  "foot-pounds-to-megawatt-hours",
  "foot-pounds-to-ev",
  "foot-pounds-to-btu",
  "foot-pounds-to-kbtu",
  "square-kilometers-to-square-meters",
  "square-kilometers-to-square-centimeters",
  "square-kilometers-to-square-miles",
  "square-kilometers-to-square-yards",
  "square-kilometers-to-square-feet",
  "square-kilometers-to-square-inches",
  "square-kilometers-to-hectares",
  "square-kilometers-to-acres",
  "square-meters-to-square-kilometers",
  "square-meters-to-square-centimeters",
  "square-meters-to-square-miles",
  "square-meters-to-square-yards",
  "square-meters-to-square-feet",
  "square-meters-to-square-inches",
  "square-meters-to-hectares",
  "square-meters-to-acres",
  "square-centimeters-to-square-kilometers",
  "square-centimeters-to-square-meters",
  "square-centimeters-to-square-miles",
  "square-centimeters-to-square-yards",
  "square-centimeters-to-square-feet",
  "square-centimeters-to-square-inches",
  "square-centimeters-to-hectares",
  "square-centimeters-to-acres",
  "square-miles-to-square-kilometers",
  "square-miles-to-square-meters",
  "square-miles-to-square-centimeters",
  "square-miles-to-square-yards",
  "square-miles-to-square-feet",
  "square-miles-to-square-inches",
  "square-miles-to-hectares",
  "square-miles-to-acres",
  "square-yards-to-square-kilometers",
  "square-yards-to-square-meters",
  "square-yards-to-square-centimeters",
  "square-yards-to-square-miles",
  "square-yards-to-square-feet",
  "square-yards-to-square-inches",
  "square-yards-to-hectares",
  "square-yards-to-acres",
  "square-feet-to-square-kilometers",
  "square-feet-to-square-meters",
  "square-feet-to-square-centimeters",
  "square-feet-to-square-miles",
  "square-feet-to-square-yards",
  "square-feet-to-square-inches",
  "square-feet-to-acres",
  "square-inches-to-square-kilometers",
  "square-inches-to-square-meters",
  "square-inches-to-square-centimeters",
  "square-inches-to-square-miles",
  "square-inches-to-square-yards",
  "square-inches-to-square-feet",
  "square-inches-to-hectares",
  "square-inches-to-acres",
  "hectares-to-square-kilometers",
  "hectares-to-square-meters",
  "hectares-to-square-centimeters",
  "hectares-to-square-miles",
  "hectares-to-square-yards",
  "hectares-to-square-inches",
  "hectares-to-acres",
  "acres-to-square-kilometers",
  "acres-to-square-meters",
  "acres-to-square-centimeters",
  "acres-to-square-miles",
  "acres-to-square-yards",
  "acres-to-square-feet",
  "acres-to-square-inches",
  "acres-to-hectares",
  "hectares-to-square-feet",
  "square-feet-to-hectares",
  "nm-to-micrometers",
  "nm-to-mm",
  "nm-to-cm",
  "nm-to-meters",
  "nm-to-km",
  "nm-to-miles",
  "nm-to-yards",
  "nm-to-feet",
  "nm-to-inches",
  "nm-to-nautical-miles",
  "micrometers-to-nm",
  "micrometers-to-mm",
  "micrometers-to-cm",
  "micrometers-to-meters",
  "micrometers-to-km",
  "micrometers-to-miles",
  "micrometers-to-yards",
  "micrometers-to-feet",
  "micrometers-to-inches",
  "micrometers-to-nautical-miles",
  "mm-to-nm",
  "mm-to-micrometers",
  "mm-to-cm",
  "mm-to-meters",
  "mm-to-km",
  "mm-to-miles",
  "mm-to-yards",
  "mm-to-feet",
  "mm-to-inches",
  "mm-to-nautical-miles",
  "cm-to-nm",
  "cm-to-micrometers",
  "cm-to-mm",
  "cm-to-meters",
  "cm-to-km",
  "cm-to-miles",
  "cm-to-yards",
  "cm-to-feet",
  "cm-to-inches",
  "cm-to-nautical-miles",
  "meters-to-nm",
  "meters-to-micrometers",
  "meters-to-mm",
  "meters-to-cm",
  "meters-to-km",
  "meters-to-miles",
  "meters-to-yards",
  "meters-to-feet",
  "meters-to-inches",
  "meters-to-nautical-miles",
  "km-to-nm",
  "km-to-micrometers",
  "km-to-mm",
  "km-to-cm",
  "km-to-meters",
  "km-to-miles",
  "km-to-yards",
  "km-to-feet",
  "km-to-inches",
  "km-to-nautical-miles",
  "miles-to-nm",
  "miles-to-micrometers",
  "miles-to-mm",
  "miles-to-cm",
  "miles-to-meters",
  "miles-to-km",
  "miles-to-yards",
  "miles-to-feet",
  "miles-to-inches",
  "miles-to-nautical-miles",
  "yards-to-nm",
  "yards-to-micrometers",
  "yards-to-mm",
  "yards-to-cm",
  "yards-to-meters",
  "yards-to-km",
  "yards-to-miles",
  "yards-to-feet",
  "yards-to-inches",
  "yards-to-nautical-miles",
  "feet-to-nm",
  "feet-to-micrometers",
  "feet-to-mm",
  "feet-to-cm",
  "feet-to-meters",
  "feet-to-km",
  "feet-to-miles",
  "feet-to-yards",
  "feet-to-inches",
  "feet-to-nautical-miles",
  "inches-to-nm",
  "inches-to-micrometers",
  "inches-to-mm",
  "inches-to-cm",
  "inches-to-meters",
  "inches-to-km",
  "inches-to-miles",
  "inches-to-yards",
  "inches-to-feet",
  "inches-to-nautical-miles",
  "nautical-miles-to-nm",
  "nautical-miles-to-micrometers",
  "nautical-miles-to-mm",
  "nautical-miles-to-cm",
  "nautical-miles-to-meters",
  "nautical-miles-to-km",
  "nautical-miles-to-miles",
  "nautical-miles-to-yards",
  "nautical-miles-to-feet",
  "nautical-miles-to-inches",
  "long-ton-to-tons",
  "tons-to-long-ton",
  "tons-to-kg",
  "kg-to-tons",
  "tons-to-milligrams",
  "milligrams-to-tons",
  "tons-to-grams",
  "grams-to-tons",
  "micrograms-to-tons",
  "tons-to-micrograms",
  "stone-to-tons",
  "tons-to-stone",
  "tons-to-oz",
  "oz-to-tons",
  "tons-to-lbs",
  "lbs-to-tons",
  "tons-to-short-ton",
  "short-ton-to-tons"
]
