export function getSettings(advancedOptions) {
    const hidden = [];
    const selection = {};
    const fieldNames = {};

    function parseChildren(field) {
        if (field.hidden) {
            hidden.push(field.name);
        }
        fieldNames[field.id] = field.name;

        if (field.type === "checkbox") {
            selection[field.id] =
                field.default_value === "__checked__" ||
                field.default_value === "true" ||
                field.default_value === true;
        } else if (field.type === "slider") {
            selection[field.id] = parseInt(field.default_value);
        } else if (["select", "radio", "radio_button"].includes(field.type)) {
            field.metas.forEach((meta) => {
                if (meta.is_default) {
                    selection[field.id] = meta._id;
                }
                fieldNames[meta._id] = meta.value;
            });
        } else if (field.default_value !== "") {
            selection[field.id] = field.default_value;
        }
        if (field.children) {
            field.children.map(parseChildren);
        }
    }
    for (const g in advancedOptions) {
        if (advancedOptions[g].options) {
            advancedOptions[g].options.map(parseChildren);
        }
    }
    return {
        fieldNames,
        hidden,
        selection,
        list: advancedOptions,
    };
}

export function childValidator(field, selection) {
    if (!selection) return false;
    if (
        selection[field.id] !== undefined &&
        field.children &&
        field.children.length
    ) {
        return field.children.filter((child) => {
            let valid = false;
            if (typeof child.parent_values[0] === "boolean") {
                valid = selection[field.id] === child.parent_values[0];
            } else {
                valid = child.parent_values.includes(selection[field.id]);
            }
            return valid;
        });
    }
    return false;
}

export function parentValidator(field, selection) {
    const isValid = (condition) => {
        if (condition.optional && selection[condition.option] === undefined) {
            return true;
        }
        return condition.meta.includes(selection[condition.option]);
    };
    if (
        !field.conditions ||
        !field.conditions.length ||
        field.conditions.every(isValid)
    ) {
        return true;
    }
    return false;
}

function getTrimOptionNames(name) {
    const optionNames = $nuxt.$store.state.advancedSetting.userData.fieldNames;
    return (
        Object.values(optionNames).find(
            (val) => typeof val === "string" && val.includes(name)
        ) || name
    );
}

function getTrimOptionId(name) {
    const targetValue = getTrimOptionNames(name);
    return Object.keys(
        $nuxt.$store.state.advancedSetting.userData.fieldNames
    ).find(
        (key) =>
            $nuxt.$store.state.advancedSetting.userData.fieldNames[key] ===
            targetValue
    );
}

/**
 *
 * @param {*} list
 * @param {*} selection
 * @param {*} full | Whether to return all the options present
 * @param {*} final | Whether the options are final before job creation
 * in the UI or just the options needed for job creation.
 * @returns
 */
export function parseSelection(list, selection, full = false, final = false) {
    const finalSelection = {}
    if (!selection) return false;
    const cropOptions = {};
    const cropOptionsNames = [
        "video_crop_width,video_crop_height",
        "offset-position-x",
        "offset-position-y",
    ];
    const parseOption = (option, selection) => {
        if (full) finalSelection[option.name] = option.metas && option.metas.length
            ? option.metas?.find(m => m._id === selection[option.id])?.value || null
            : selection[option.id]
        if (!option.hidden && parentValidator(option, selection)) {
            if (option.metas && option.metas.length) {
                option.metas.forEach((meta) => {
                    if (selection && (meta._id === selection[option.id])) finalSelection[option.name] = meta.value
                })
            } else {
                finalSelection[option.name] = selection[option.id]
            }
        }

        // keep video-crop related options to assemble later
        if (option.hidden && cropOptionsNames.includes(option.name)) {
            cropOptions[option.name] = selection[option.id];
        }

        if (option.children && option.children.length) {
            const childs = childValidator(option, selection)
            if (childs && Array.isArray(childs)) { childs.forEach(child => parseOption(child, selection)) }
        }
    }
    if (Array.isArray(list)) {
        list.forEach((ao) => {
            if (ao.options && ao.options.length) {
                ao.options.forEach(option => parseOption(option, selection))
            }
        })
    }
    if (finalSelection.subtitle && finalSelection.subtitle.content) {
        finalSelection.subtitle = finalSelection.subtitle.content
    }
    /**
     * We are checking several advanced options
     * manually as we don't serve it from the backend. We 
     * have a UI elements that sets these options
     * and if it's set, we simply send it with the request.
     */
    if (selection && selection['image_rotation'] != undefined && selection['image_rotation'] != '0') finalSelection['image_rotation'] = selection['image_rotation']
    if (selection && selection['image_top_margin'] != undefined && selection['image_top_margin'] != '0') finalSelection['image_top_margin'] = selection['image_top_margin']
    if (selection && selection['image_left_margin'] != undefined && selection['image_left_margin'] != '0') finalSelection['image_left_margin'] = selection['image_left_margin']

    /**
     * Check if there's gif_delay fields. We combine the individual gif_delay values into
     * an array and submit it as an option in the final merge task.
     */

    const gifDelays = Object.entries($nuxt.$store.state.advancedSetting.userData.allSelection)
        .filter(([key, value]) => key !== 'root' && key !== 'page' && 'gif_frame_speed' in value)
        .map(([, value]) => value['gif_frame_speed']);

    if (gifDelays && gifDelays.length && $nuxt.$store.state.advancedSetting.merging) finalSelection['gif_frame_speed'] = JSON.stringify(gifDelays)

    if (Object.keys(cropOptions).length === 3) {
        const width = cropOptions["video_crop_width,video_crop_height"].split(",")[0];
        const height = cropOptions["video_crop_width,video_crop_height"].split(",")[1];
        if (width > 0 && height > 0) {
            const offsetX = parseInt(cropOptions["offset-position-x"]);
            const offsetY = parseInt(cropOptions["offset-position-y"]);
            finalSelection["video_crop"] = `${width}:${height}:${offsetX}:${offsetY}`;
        }
    }

    /**
     * We are checking the image rotation advanced option
     * manually as we don't serve it from the backend. We
     * have a UI button that sets the image rotation option
     * and if it's set, we simply send it with the request.
     */
    if (
        selection &&
        selection["image_rotation"] != undefined &&
        selection["image_rotation"] != "0"
    ) {
        finalSelection["image_rotation"] = selection["image_rotation"];
    }

    /**
     * For trim if start and end both values are 00:00:00,
     * we assume a trim operation is not intended. In this
     * case, we would not send the trim options to the
     * server.
     */
    const isPreviewTemplate = $nuxt.$store.state.info?.template === "Preview";
    if (
        isPreviewTemplate &&
        final &&
        selection &&
        selection[getTrimOptionId("cut_start")] === "00:00:00" &&
        selection[getTrimOptionId("cut_end")] === "00:00:00"
    ) {
        delete finalSelection[getTrimOptionNames("cut_start")];
        delete finalSelection[getTrimOptionNames("cut_end")];
    }

    return finalSelection;
};

const getSlug = () => {
    if ($nuxt.$store.state.advancedSetting.merging) return $nuxt.$store.state.advancedSetting.merging;
    const info = $nuxt.$store.state.info
    if (info.slug) return info.slug;
    return info.source.ext + "-to-" + info.target.ext;
}

export function checkIfOptionExistsForAllFiles(optionID) {
    const userSettings = {
        ...$nuxt.$store.state.advancedSetting.userData.allSelection,
    };
    delete userSettings.root;
    delete userSettings.page;

    let mergeOptionCount = 0;

    for (const childObjectKey in userSettings) {
        if (userSettings.hasOwnProperty(childObjectKey)) {
            const childObject = userSettings[childObjectKey];
            if (childObject.hasOwnProperty(optionID)) {
                mergeOptionCount++;
            }
        }
    }
    return (
        mergeOptionCount > 1 &&
        mergeOptionCount === Object.keys(userSettings).length
    );
}
