import {fetchUserInfo} from '~/fc/Dal/AuthRepo'
export async function getIp () {
  try {
    let { data: { ip } } = await fetchUserInfo()
    return ip
  } catch (e) {
    return null
  }
}

export async function getGeo (ip) {
  const _ip = ip || getIp()
  let geo = await fetch('http://ip-api.com/json/' + _ip)
  geo = (await geo.json())
  return geo
}
