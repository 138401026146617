export const STATUS_DOWNLOADING = 0;
export const STATUS_READY = 10;
export const STATUS_CREATING_JOB = 19;
export const STATUS_UPLOADING = 20;
export const STATUS_REUPLOADING = 21;
export const STATUS_VALIDATING_UPLOAD = 23;
export const STATUS_FINISHING_UPLOAD = 24;
export const STATUS_CONVERTING = 30;
export const STATUS_EXPORTING = 31;
export const STATUS_IMPORTING = 32;
export const STATUS_DONE = 40;
export const STATUS_ERROR = 50;
export const STATUS_DRM_PROTECTED_FILE_ERROR = 51;
export const STATUS_ERROR_FILE_TOO_BIG = 60;
export const STATUS_UPLOAD_FAILED = 70;
export const STATUS_UPLOAD_TIMEOUT = 73;
export const MAX_FILE_SIZE_EXCEEDED = 71;
export const STATUS_INTERRUPTED = 81;
export const STATUS_LIMIT_REACH_ERROR = 80;
export const STATUS_EXPIRED = 90;
export const STATUS_TASK_DELETED = 91;
export const STATUS_SERVER_ERROR = 92;
export const STATUS_RETRYING = 39;
export const STATUS_UNAUTHORIZED = 401;

// these are the keys for localization
export const STATUS_LABELS = {
  0: "downloading",
  10: "ready",
  19: "creating_job",
  20: "uploading",
  21: "restarting_upload",
  23: "validating_upload",
  24: "finishing_upload",
  30: "converting",
  31: "exporting",
  32: "importing",
  40: "done",
  50: "error",
  51: "drm_protected_file",
  60: "error_file_too_big",
  70: "upload_failed",
  73: "upload_timeout",
  71: "size_exceeded",
  80: "rejected",
  81: "interrupted",
  90: "expired",
  91: "deleted",
  92: "server_error",
  39: "retrying",
  401: "unauthorized",
};

const operationOrderValue = {
  import: 10,
  convert: 20,
  compress: 20,
  merge: 20,
  archive: 20,
  export: 30,
};
const statusOrderValue = {
  created: 1,
  processing: 2,
  completed: 3,
  insufficiant_minute: 4,
  failed: 5,
  canceled: 6,
  deleted: 7,
};

export function getEventOrderValue(operation = "", status = "") {
  let total = 0;
  if (operation.includes("import")) {
    total += operationOrderValue["import"];
  } else if (operation.includes("export")) {
    total += operationOrderValue["export"];
  } else if (["convert", "compress", "merge", "archive"].includes(operation)) {
    total += operationOrderValue[operation];
  }

  if (statusOrderValue[status]) {
    total += statusOrderValue[status];
  }
  return total;
}
