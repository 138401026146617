import { generateRandomString } from "~/fc/helpers";

class ToastManager {
  constructor(ctx) {
    this.store = ctx.$store;
    this.toasts = this.store.state.toasts;
  }

  /**
   * Add a new toast to the toast queue.
   *
   * @param {string} type - The type of the toast notification (e.g., 'success', 'warning', 'error').
   * @param {string} title - The title of the toast notification.
   * @param {string} text - The text content of the toast notification.
   * @param {Object} [options={}] - Additional options for the toast notification.
   * @param {boolean} [options.offLine=false] - Indicates if the notification should be displayed offline.
   * @param {boolean} [options.onLine=false] - Indicates if the notification should be displayed online.
   */
  add(type, title, text, options = {}) {
    options = {
      offLine: false,
      onLine: false,
      ...options,
    };

    const toast = {
      id: generateRandomString(5),
      type,
      title,
      text,
      ...options,
    };

    // remove offLine toaster if it's a online toaster
    if (options.onLine) this.removeOffLineToaster();

    this.store.commit("addToast", toast);
  }

  removeOffLineToaster() {
    let toasts = JSON.parse(JSON.stringify(this.toasts));
    toasts = toasts.filter((t) => !t.offLine);
    this.store.commit("setToasts", toasts);
  }

  remove(id) {
    let toasts = JSON.parse(JSON.stringify(this.toasts));
    toasts = toasts.filter((t) => id !== t.id);
    this.store.commit("setToasts", toasts);
  }
}

export default ToastManager;

/*
  USAGE
  (new ToastManager(this).add('success', 'Conversion Completed!', 'Please download the files.'))
  (new ToastManager(this).add('danger', 'Conversion Failed', 'Your File contents virus.'))
  (new ToastManager(this).add('info', 'Do you know?', 'Some Information.'))
  (new ToastManager(this).add('warning', 'Your Limit Expired', 'Please buy some more time.'))
*/
