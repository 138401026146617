export default function (ctx) {
  const userAgent = ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent

  ctx.isCypress = /cypress/i.test(userAgent)
  ctx.store.commit('store/updateIsCypress', ctx.isCypress)

  ctx.isMobile = /mobile|android|ios|iPad/i.test(userAgent)
  ctx.store.commit('store/updateIsMobile', ctx.isMobile)

  ctx.isTab = /iPad/i.test(userAgent)
  ctx.store.commit('store/updateIsTab', ctx.isTab)

  ctx.isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
  ctx.store.commit('store/updateIsSafari', ctx.isSafari)

  ctx.store.commit('store/updateUserAgent', userAgent)
}
