
  export default {
    name: "NotFound",
    props: ['error'],
    mounted() {
      this.$store.commit('updateBreadcrumbs', [
        {
          url: this.localePath('#'),
          label: this.$t('page_not_found')
        }
      ])
    }
  }
