import HttpService from "../HttpService";

export async function fetchOptionsForExt(ext) {
  const locale = $nuxt.$store.$i18n.locale;
  const operation = $nuxt.$store.info?.operation || 'convert';
  const { data } = await HttpService.query()
    .setPath(`/view/options?operation=${operation}&input_format=${ext}&lang=${locale}`)
    .get();
  return data;
}

export async function fetchAdvancedOptionsForExt(operation, srcExt, targetExt) {
  const locale = $nuxt.$store.$i18n.locale;
  const urlParams = [
    "include=options",
    `operation=${operation}`,
    `output_format=${targetExt.toLowerCase()}`,
    `lang=${locale}`,
  ];
  if (srcExt) {
    urlParams.push(`input_format=${srcExt.toLowerCase()}`);
  }

  const { data } = await HttpService.query()
    .setPath(`/view/options?` + urlParams.join("&"))
    .get();
  return data;
}

export async function getType(ext, lang) {
  try {
    const { data } = await HttpService.query()
      .setPath(
        "/view/options?operation=convert&input_format=" + ext + "&lang=" + lang
      )
      .get();
    return data.type;
  } catch (e) {
    return null;
  }
}

export async function savePreset(token, postData) {
  const { data } = await HttpService.query()
    .setPath("/presets")
    .setToken(token)
    .setBody(postData)
    .post();
  return data;
}

export async function getPresets(token, page) {
  const { data } = await HttpService.query()
    .setPath("/presets")
    .setToken(token)
    .setParams({ page, per_page: 10 })
    .get();
  return data;
}

export async function deletePreset(token, id) {
  return await HttpService.query()
    .setPath("/presets/" + id)
    .setToken(token)
    .delete();
}

export async function fetchSupportedTargets () {
  return await HttpService.query()
    .setPath(`/view/conversion-support`)
    .get();
}


