import { truncate } from '../helpers'

export function getProperName(name) {
	return name.replace(/[/\\#+$~%'"*?<>{}]/g, '').replace(/\s+/, ' ')
}

export function getFileName(url) {
	const name = url ? url.substr(1 + url.lastIndexOf('/')).split('?')[0] : ''

	if (name && name.lastIndexOf('#') > name.lastIndexOf('.')) {
		return name.substr(0, name.lastIndexOf('#'))
	} else {
		return name
	}
}

export function getExt(url) {
	return (
		url = getFileName(url)
	).substr(url.lastIndexOf('.') + 1)
}

export function uuidv4() {
	return 'xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0
		const v = c === 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

export function formatSize(size) {
	size = parseFloat(size)
	if (size > 1024 * 1024) { return parseFloat(size / (1024 * 1024)).toFixed(2) + ' MB' }
	if (size > 1024) { return parseFloat(size / 1024).toFixed(2) + ' KB' }
	if (size > 0) { return parseFloat(size).toFixed(2) + ' B' }
	return ''
}

export function truncateName(file) {
	return { ...file, name: truncate(file.name, 50) }
}
