import { OLD_UNITS } from '../fc/Constants/old-units'

const Redirections = {
  'pdf-compressor': 'compress-pdf',
  'ebook-to-pdf': 'pdf-Converter',
  '/convert/gif-to-apng' : 'gif-to-apng',
  '/convert/apng-to-gif' : 'apng-to-gif'
}
export default function ({ req, route, $axios, res, redirect }) {
  const fullPath = route.fullPath
  const id = route.params.id
  if (Redirections[fullPath]) { redirect(301, '/' + Redirections[fullPath]) }

  if (OLD_UNITS.includes(id)) { redirect(301, '/unit/' + id) }
}
