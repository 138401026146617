import {
  STATUS_ERROR,
  STATUS_LABELS,
  MAX_FILE_SIZE_EXCEEDED,
  STATUS_UPLOAD_FAILED,
  STATUS_INTERRUPTED,
  STATUS_LIMIT_REACH_ERROR,
  STATUS_VALIDATING_UPLOAD,
  STATUS_FINISHING_UPLOAD,
  STATUS_CREATING_JOB,
  STATUS_UPLOAD_TIMEOUT,
  STATUS_UNAUTHORIZED,
} from "~/fc/Constants/job-status";
import { STORAGE_QUOTA_EXCEEDED } from "../Constants/task-error-code";

/**
 * Handles unknown upload errors by updating the task status
 * @param {string} id - The ID of the task.
 * @param {string} errorMessage - The error message associated with the upload failure.
 */
export const handleUnknownUploadError = (id, errorMessage) => {
  let statusText = STATUS_LABELS[STATUS_UPLOAD_FAILED];
  let statusTitle = $nuxt.$t(statusText);
  if (errorMessage === STATUS_LABELS[STATUS_UPLOAD_TIMEOUT]) {
    statusText = STATUS_LABELS[STATUS_ERROR];
    statusTitle = $nuxt.$t(errorMessage);
  }

  $nuxt.$store.commit("items/taskFailed", {
    id,
    type: "custom",
    status: STATUS_ERROR,
    statusText,
    statusTitle,
  });

  $nuxt.$sentry.captureEvent({
    message: "Upload Failed - " + errorMessage,
    extra: {
      task: id,
      reason: errorMessage,
    },
  });
};

/**
 * Handles the upload error and performs necessary actions based on the error type.
 * Updates job status, adds the job to the "interrupted" state, marks the task as failed, or handles unknown upload errors.
 * @param {Object} options - The options for handling the upload error.
 * @param {Object} options.err - The upload error object.
 * @param {string} options.jobId - The ID of the job.
 * @param {string} options.taskId - The ID of the task.
 * @param {string} options.fileUploadUrl - The file upload URL.
 * @param {string} options.identifier - The file identifier.
 * @param {string} options.signature - The file signature.
 * @param {string} options.fileName - The file name.
 */
export const handleUploadError = ({
  err,
  jobId,
  taskId,
  fileUploadUrl,
  identifier,
  signature,
  fileName,
  fileSize,
}) => {
  // if this job is already removed but this error handler might be called from an ongoing operation.
  // in this kind of case, no need to proceed further.
  if (!$nuxt.$store.state.items.jobs[jobId]) return;

  // safety code
  if (typeof err === "string") {
    err = { message: err };
  }

  const reason = err?.message ? String(err.message) : "";
  if (
    [STATUS_VALIDATING_UPLOAD, STATUS_FINISHING_UPLOAD].indexOf(err?.step) >=
      0 &&
    err?.status === STATUS_LABELS[STATUS_INTERRUPTED]
  ) {
    const stepName = $nuxt.$t(STATUS_LABELS[err.step]);
    let statusTitle = $nuxt
      .$t("interrupted_no_internet")
      .replace("STEP_NAME", stepName);

    if ($nuxt.$store.state.isInternetAvailable) {
      statusTitle = $nuxt
        .$t("interrupted_has_internet")
        .replace("STEP_NAME", stepName)
        .replace("REASON", reason);
    }

    // update job status in store
    $nuxt.$store.commit("items/setJobProgress", {
      id: jobId,
      status: STATUS_INTERRUPTED,
      statusText: STATUS_LABELS[STATUS_INTERRUPTED],
      statusTitle,
      progress: 0,
    });

    // add the job to "interrupted" state
    $nuxt.$store.commit("addJobToInterruptedState", {
      id: jobId,
      taskId: taskId,
      fileUploadUrl,
      identifier,
      signature,
      fileName,
      step: err.step,
      fileSize,
    });
  } else if (reason.includes(STATUS_LABELS[MAX_FILE_SIZE_EXCEEDED])) {
    // mark the task as failed due to exceeding the maximum file size
    $nuxt.$store.commit("items/taskFailed", {
      id: taskId,
      type: "custom",
      status: STATUS_ERROR,
      statusText: STATUS_LABELS[MAX_FILE_SIZE_EXCEEDED],
    });
  } else if (
    [415, 413].indexOf(err?.status) >= 0 ||
    reason === STORAGE_QUOTA_EXCEEDED
  ) {
    // mark the task as failed due to exceeding storage quota
    $nuxt.$store.commit("items/taskFailed", {
      id: taskId,
      type: "custom",
      status: STATUS_ERROR,
      statusText: STATUS_LABELS[STATUS_ERROR],
      statusTitle: $nuxt.$t(STORAGE_QUOTA_EXCEEDED),
    });
  } else {
    if (reason === STATUS_LABELS[STATUS_ERROR]) {
      // this error is generated from resumable upload
      // and it indicates that the job is already in error state in the store and the error is already handled.
      // so, no further action needed.
      return;
    }
    // handle unknown upload errors
    handleUnknownUploadError(taskId, reason);
  }
};

/**
 * Handles the job creation error and performs necessary actions based on the error type.
 * Updates job status, adds the job to the "interrupted" state, and sends Sentry events.
 * @param {Object} options - The options for handling the job creation error.
 * @param {Object} options.err - The job creation error object.
 * @param {string} options.jobId - The ID of the job.
 * @param {Object} options.file - The file object.
 * @param {boolean} options.returnJob - Flag indicating whether to return the job object or its ID.
 * @param {Array} options.preTasks - The tasks for the job.
 * @param {string} options.type - The type of the job.
 * @param {boolean} [options.isBulkItemJob=false] - Flag indicating whether it is a bulk_item job.
 * @param {Object} [options.uploads={}] - The uploads for the job.
 * @returns {Object|string} - The job object or its ID based on the returnJob flag.
 */
export const handleJobCreationError = ({
  err,
  jobId,
  file,
  returnJob,
  preTasks,
  type,
  isBulkItemJob = false,
  uploads = {},
}) => {
  // if this job is already removed but this error handler might be called from an ongoing operation.
  // in this kind of case, no need to proceed further.
  if (!$nuxt.$store.state.items.jobs[jobId]) return;

  const defaultJob = {
    id: jobId,
    tasks: [],
    type,
    step: err?.step,
    progress: 0,
  };

  const user = { id: err?.userId };
  const extra = { reason: `${err?.message} - ${err?.code}` };

  if (err?.status === 429) {
    const job = {
      ...defaultJob,
      status: STATUS_LIMIT_REACH_ERROR,
      statusText: STATUS_LABELS[STATUS_LIMIT_REACH_ERROR],
      statusTitle: $nuxt.$t("conversion_rate_limit_reached"),
    };
    $nuxt.$sentry.captureEvent({
      message: "GetJob 429",
      user,
      extra,
    });

    $nuxt.$store.commit("items/setJob", job);
    return returnJob ? job : job.id;
  } else if (err?.status === 402) {
    const job = {
      ...defaultJob,
      status: STATUS_LIMIT_REACH_ERROR,
      statusText: STATUS_LABELS[STATUS_LIMIT_REACH_ERROR],
      statusTitle: $nuxt.$t("out_of_conversion_minutes"),
    };

    $nuxt.$sentry.captureEvent({
      message: "GetJob 402",
      user,
      extra,
    });

    $nuxt.$store.commit("items/setJob", job);
    $nuxt.$store.commit("banner/showLimitReachedError");

    if ($nuxt.$store.state.subscription.name === "Free") {
      $nuxt.$gtm.push({ event: "rejected_out_of_mins_free" });
    } else {
      $nuxt.$gtm.push({ event: "rejected_out_of_mins_pro" });
    }

    return returnJob ? job : job.id;
  } else if (err?.status === STATUS_LABELS[STATUS_UNAUTHORIZED]) {
    const job = {
      ...defaultJob,
      status: STATUS_UNAUTHORIZED,
      statusText: STATUS_LABELS[STATUS_UNAUTHORIZED],
      statusTitle: $nuxt.$t("not_authorized"),
    };

    $nuxt.$store.commit("items/setJob", job);
    return returnJob ? job : job.id;
  } else if (err?.status === STATUS_LABELS[STATUS_INTERRUPTED]) {
    const stepName = $nuxt.$t(STATUS_LABELS[STATUS_CREATING_JOB]);
    const reason = err?.message || "";
    let statusTitle = $nuxt
      .$t("interrupted_no_internet")
      .replace("STEP_NAME", stepName);

    if ($nuxt.$store.state.isInternetAvailable) {
      statusTitle = $nuxt
        .$t("interrupted_has_internet")
        .replace("STEP_NAME", stepName)
        .replace("REASON", reason);
    }

    const job = {
      ...defaultJob,
      status: STATUS_INTERRUPTED,
      statusText: STATUS_LABELS[STATUS_INTERRUPTED],
      statusTitle,
    };

    // update job status in store
    $nuxt.$store.commit("items/setJob", job);

    // add the job to "interrupted" state
    $nuxt.$store.commit("addJobToInterruptedState", {
      id: job.id,
      file,
      step: err.step,
      preTasks,
      type,
      isBulkItemJob,
      uploads,
      returnJob,
      fileSize: file.total,
    });

    let message = "GetJob Others";
    if (err?.code === "ECONNABORTED") {
      message = "GetJob ECONNABORTED";
    }

    $nuxt.$sentry.captureEvent({
      message,
      user,
      extra,
    });

    return returnJob ? job : job.id;
  } else {
    const job = {
      ...defaultJob,
      status: STATUS_ERROR,
      statusText: STATUS_LABELS[STATUS_UPLOAD_FAILED],
      statusTitle: $nuxt.$t(STATUS_LABELS[STATUS_UPLOAD_FAILED]),
    };

    $nuxt.$store.commit("items/setJob", job);

    return returnJob ? job : job.id;
  }
};
