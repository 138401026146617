import {STATUS_LIMIT_REACH_ERROR, STATUS_CREATING_JOB} from '~/fc/Constants/job-status'
import {resume} from '~/fc/Dal/JobRepo'
import Converter from './Converter'

/**
 * Generages a list of Failed Job IDs
 * @returns Array of Job IDs
 */
const getFailedJobIds = () => {
    const jobs = $nuxt.$store.state.items.jobs
    return Object.keys({...jobs}).map(key => {
                return { id: key, ...jobs[key] };
            })
            .filter(job => {
                return job.tasks && job.tasks.length && job.status === STATUS_LIMIT_REACH_ERROR
            })
            .map (job => job.id)
}

/**
 * Generages a list of Dummy Jobs
 * @returns Array of Job Objects
 */
const getDummyJobIds = () => {
    const jobs = $nuxt.$store.state.items.jobs
    return Object.keys({...jobs}).map(key => {
                return { id: key, ...jobs[key] };
            })
            .filter(job => {
                return (job.status !== STATUS_CREATING_JOB) && (!job.tasks || !job.tasks.length)
            })
}

export const getInturruptedJobIds = () => {
    return [...getFailedJobIds(), ...getDummyJobIds()]
}

/**
 * There are several types of jobs that
 * fall under 'rejected' status when the 
 * user runs out of conversion minutes. 
 * This method would resume those jobs.
 */
export const retryFailedJobs = () => {
    retryUnfinishedJobs()
    retryDummyJobs()
}
/**
 * These are the jobs that are created 
 * but interrupted mid-conversion because 
 * the limit of conversion minutes has 
 * been reached.
 * PerTaskLimitReachedError
 * banner - 
 * "NAME users get LIMIT minutes per task. 
 * Upgrade to restart the failed tasks."
 * After the user buys minute, we would
 * restart the failed tasks of that job.
 */
const retryUnfinishedJobs = () => {
    const interruptedJobIDs = getFailedJobIds()
    interruptedJobIDs.forEach(async (job_id) => {
        $nuxt.$store.commit('items/setJobRetrying', job_id)
        await resume(job_id)
    })
}

/**
 * These are the jobs that fail to be created 
 * in the DB and a dummy job is created 
 * instead to update the UI.
 * After the user buys minutes, we simply 
 * create a new job and replace the dummy one.
 */
const retryDummyJobs = () => {
    const dummyJobs = getDummyJobIds()
    const files = $nuxt.$store.state.list
    dummyJobs.forEach(async (job) => {
        if ($nuxt.$store.state.advancedSetting.merging) {
            $nuxt.$store.commit('items/setJobRetrying', job.id)
            $nuxt.$bus.$emit('START_CURRENT_BULK_JOB')
        } else {
            const file = files.find(file => file.job_id === job.id)
            new Converter($nuxt).setFile(file).convert()
            $nuxt.$store.commit('items/removeJob', job.id)
        }
    })
}